<template>
  <div
    class="relative cursor-pointer border-t border-l border-r rounded-t-md border-everWhite0  mr-2"
    :class="isActive ? 'text-scanMainColor' : ''">
    <div class="py-2 px-4">
      {{ option }}
    </div>
    <div v-if="isActive" class="w-full h-2 absolute -bottom-1 bg-white" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    option: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style>

</style>
