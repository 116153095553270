<template>
  <div class="flex border-b border-everWhite0 mt-6">
    <TabItem
      v-for="item in tabItem"
      :key="item.id"
      :option="item.option"
      :is-active="currentTxOptions === item.option"
      @click="switchOptions(item.option)" />
  </div>
</template>

<script>

import { defineComponent } from 'vue'
import TabItem from './TabItem.vue'
export default defineComponent({
  components: {
    TabItem
  },
  props: {
    currentTxOptions: {
      type: String,
      default: ''
    }
  },
  emits: ['switch'],
  setup (props, context) {
    const tabItem = [
      {
        id: 1,
        option: 'Transaction'
      },
      {
        id: 2,
        option: 'Bundle'
      }
    ]
    const switchOptions = (option) => {
      context.emit('switch', option)
    }
    return {
      tabItem,
      switchOptions
    }
  }
})
</script>

<style>

</style>
